import React from 'react';

import { withPrefix } from 'gatsby'

import Meta from '../components/meta';

import { Link } from 'gatsby';

import Contents from '../components/contents';

const Request = () =>{



    return(
        <>

            <Meta
                title="個人情報開示請求について"
                description="アクロスソリューションズの個人情報開示請求について"
            />

            <Contents>

                <article className="p-recruit-jobs is-policy">

                    <section className="p-recruit-jobs-section">

                        <div className="p-recruit-section-box is-detail">
                            <div className="p-recruit-job">
                                <p className="p-recruit-job--text">
                                個人情報開示請求について
                                </p>
                            </div>

                            <div className="p-recruit-data">

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    開示等の請求等の手続方法
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        当社では、以下の手順に則って、ご本人または代理人から、保有個人情報の「利用目的の通知」、「開示」、「訂正・追加・削除」、「利用停止・消去」、「第三者提供の停止」の請求等に対応しております。なお、ウェブサイトから直接請求することはできませんので、ご注意ください。
                                        </p>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            請求等項目
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            保有個人情報に関し次の項目が請求等できます。
                                            </p>

                                            <ul className="p-recruit-data-box-lists is-decimal">
                                                <li>
                                                利用目的の通知
                                                </li>
                                                <li>
                                                開示
                                                </li>
                                                <li>
                                                訂正・追加・削除（内容が事実でない場合）
                                                </li>
                                                <li>
                                                利用停止・消去（目的外利用がある場合または不正の手段により取得した場合）
                                                </li>
                                                <li>
                                                第三者提供の停止（法令に違反して第三者に提供した場合）
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="p-recruit-data-box-list">
                                            <p className="p-recruit-data-box-list--title">
                                            受付窓口
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            〒920-0022<br />
                                            石川県金沢市北安江3丁目6-6 メッセヤスダ1F/2F<br />
                                            管理部　個人情報保護相談窓口担当<br />
                                            電話番号：076-255-2012<br />
                                            （受付時間：平日9時～18時　土・日・祝日除く）
                                            </p>
                                        </div>

                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    開示できない場合
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        以下に定める事項に該当した場合は、不開示とさせていただきます。なお、不開示を決定した場合も、その旨の回答を書面にて通知いたします。また、不開示の場合についても所定の手数料をいただきますので、ご了承ください。
                                        </p>

                                        <div className="p-recruit-data-box-list">
                                            <ul className="p-recruit-data-box-lists is-decimal">
                                                <li>
                                                本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
                                                </li>
                                                <li>
                                                当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
                                                </li>
                                                <li>
                                                他の法令に違反することとなる場合
                                                </li>
                                                <li>
                                                所定の請求書に未記入があった場合、所定の書類が不足していた場合
                                                </li>
                                                <li>
                                                手数料が不足していた場合
                                                </li>
                                                <li>
                                                その他開示出来ない事由がある場合
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    自己情報の開示等の請求等の流れ
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--title">
                                        【1. 請求書記入】 →【2. 請求書送付】→【 3. 回答書受領】
                                        </p>

                                        <div className="p-recruit-data-box-list">

                                            <p className="p-recruit-data-box-list--title">
                                            1.請求書記入
                                            </p>

                                            <p className="p-recruit-data-box-list--text">
                                            必要な請求書をダウンロードし、必要事項を全てご記入ください。
                                            </p>

                                        </div>

                                        <p className="p-recruit-data-box-cnt--text">
                                            <a href={withPrefix('/disclosure_request.pdf')} className="c-text--link" target="_blank">
                                            保有個人情報開示等請求書<i className="fa fa-file-pdf-o ml"></i>
                                            </a>
                                        </p>
                                    </div>

                                    <div className="p-recruit-data-box-cnt">
                                        <div className="p-recruit-data-box-list">

                                            <p className="p-recruit-data-box-list--title">
                                            2.請求書送付
                                            </p>

                                        </div>

                                        <p className="p-recruit-data-box-cnt--title">
                                        請求方法
                                        </p>
                                        <p className="p-recruit-data-box-cnt--text">
                                        （1）請求書、（2）本人確認書類(代理人の場合は、代理人であることを証明する書類および委任状)を添えて、郵便にてご送付ください（送料は、自己負担でお願いいたします）。
                                        </p>

                                        <p className="p-recruit-data-box-cnt--title">
                                        請求者が本人の場合の本人確認書類
                                        </p>
                                        <p className="p-recruit-data-box-cnt--text">
                                        運転免許証、パスポート、在留カード、特別永住者証明書、健康保険被保険者証、年金手帳等のコピー
                                        </p>

                                        <div className="u-margin-both__1__1">
                                            <p className="p-recruit-data-box-cnt--title">
                                            請求者が代理人の場合の本人確認書類
                                            </p>

                                            <ul className="p-recruit-data-box-lists">
                                            <li>
                                            <p className="p-recruit-data-box-cnt--text">
                                            情報主体の本人確認書類
                                            </p>
                                            <p className="p-recruit-data-box-cnt--text">
                                            運転免許証、パスポート、在留カード、特別永住者証明書、健康保険被保険者証、年金手帳のコピー
                                            </p>
                                            </li>

                                            <li>
                                            <p className="p-recruit-data-box-cnt--text">
                                            代理人の本人確認書類
                                            </p>
                                            <p className="p-recruit-data-box-cnt--text">
                                            運転免許証、パスポート、在留カード、特別永住者証明書、健康保険被保険者証、年金手帳のコピー
                                            </p>
                                            </li>

                                            <li>
                                            <p className="p-recruit-data-box-cnt--text">
                                            任意代理人の場合　　　：委任状と印鑑登録証明書
                                            </p>
                                            <p className="p-recruit-data-box-cnt--text">
                                            未成年者の保護者の場合：戸籍謄本や健康保険被保険者証等
                                            </p>
                                            <p className="p-recruit-data-box-cnt--text">
                                            成年被後見人の場合　　：登記事項証明書、家事審判書謄本等のコピー
                                            </p>
                                            </li>
                                            </ul>
                                        </div>

                                        <p className="p-recruit-data-box-cnt--title">
                                        開示等の手数料およびその徴収方法
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        １回の請求毎に、2,000円(税含まず)指定銀行口座に振込をお願い致します。
                                        振込時においては、税込み金額にて振込をお願い致します。
                                        また、振込手数料についてはご負担いただきます様にお願い致します。
                                        なお、手数料が不足していた場合及び手数料が振り込まれなかった場合は、その旨ご連絡申し上げますが、所定の期間内に対応がない場合は、開示等の請求に応じることができませんので、ご了承ください。
                                        </p>
                                    </div>

                                    <div className="p-recruit-data-box-cnt">
                                        <div className="p-recruit-data-box-list">

                                            <p className="p-recruit-data-box-list--title">
                                            請求書送付先
                                            </p>

                                        </div>

                                        <p className="p-recruit-data-box-cnt--text">
                                        〒920-0022<br />
                                        石川県金沢市北安江3丁目6-6 メッセヤスダ1F/2F<br />
                                        管理部　個人情報保護相談窓口担当<br />
                                        電話番号：076-255-2012<br />
                                        （受付時間：平日9時～18時　土・日・祝日除く）
                                        </p>

                                    </div>

                                    <div className="p-recruit-data-box-cnt">

                                        <div className="p-recruit-data-box-list">

                                            <p className="p-recruit-data-box-list--title">
                                            3.回答書受領
                                            </p>

                                        </div>

                                        <p className="p-recruit-data-box-cnt--title">
                                        開示等の請求等に対する当社からの回答方法                                        
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        当社は、請求等いただいた方が指定する記載住所宛に、原則として請求書類一式を受領した後30日以内に、回答いたします。なお、開示方法については、請求者からの指定がなければ、書面によるものとします。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    開示等の請求等に基づいて取得した個人情報の取扱い
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        「利用目的の通知」、「開示」、「訂正・追加・削除」、「利用停止・消去」、「第三者提供の停止」の請求等に基づいて取得した個人情報は、「利用目的の通知」、「開示」、「訂正・追加・削除」、「利用停止・消去」、「第三者提供の停止」の請求等に必要な範囲内で取り扱うものとします。請求書および本人確認に必要な書類等の提出書類は、当社からの回答が終了した後、廃棄いたします。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text u-align-right">
                                        以上
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </section>

                </article>

            </Contents>

        </>
    )
}

export default Request;


